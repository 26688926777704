import React, { useState, useRef } from "react";
import Select from 'react-select'
// import { Link } from "gatsby";
// import checkBlue from "../assets/png/check-blue.png";
// import checkOrange from "../assets/png/check-orange.png";
import headTitle from "../assets/png/headTitle.png";
import headText1 from "../assets/png/headText1.png";
// import headText2 from "../assets/png/headText2.png";
// import headText3 from "../assets/png/headText3.png";
import headText4 from "../assets/png/headText4.png";
import headText5 from "../assets/png/headText5.png";
import headTextC from "../assets/png/cHero.png";

import { config } from "../components";


import HelmetIndex from "../components/helmetIndex";
import { CalLogo, CalFooter } from "../components";
import { navigate } from "gatsby";

export default ({ location }) => {


  const [data, setData] = useState({
    sportName: "",
    athleteAge: "",
    zipCode: "",
    // phoneNumber: ""


  })
  const [responseError, setResponseError] = useState("")
  const [dataError, setDataError] = useState({
    sportName: false,
    athleteAge: false,
    zipCode: false,
    // phoneNumber: false
  })
  const [disabled, setDisabled] = useState(false)



  // function isValidJSON(str) {
  //   try {
  //     JSON.parse(str);
  //     return true;
  //   } catch (e) {
  //     return false;
  //   }
  // }
  const myRef = useRef(null)
  const sportArray = [
    "Baseball",
    "Basketball",
    "Bicycling",
    "Cross Country",
    "Field Hockey",
    "Flag Football",
    "Tackle Football",
    "Golf",
    "Gymnastics",
    "Ice Hockey",
    "Lacrosse",
    "Martial Arts",
    "Skateboarding",
    "Skiing/Snowboarding",
    "Soccer",
    "Softball",
    "Swimming",
    "Tennis",
    "Track and Field",
    "Volleyball"
  ]

  const options = sportArray.map((r) => ({ value: r, label: r }))
  const onSubmit = async (event) => {
    event.preventDefault()
    setResponseError("")
    setDisabled(true)
    setDataError({
      ...dataError,
      sportName: false,
      athleteAge: false,
      zipCode: false,
      // phoneNumber: false
    })
    const error = {
      sportName: false,
      athleteAge: false,
      zipCode: false,
      // phoneNumber: false
    }
    const newData = {
      ...data
    }


    //error check for sportName

    if (data.sportName === "") {
      error.sportName = true
    } else {

    }
    //check error for Phone number
    // const phoneValidateRegex = /^\d{10}$/
    // if (!phoneValidateRegex.test(data.phoneNumber)) {
    //   error.phoneNumber = true
    //   newData.phoneNumber = ""

    // }
    //check error for zip code
    const zipCodeValidateRegex = /^\d{5}$/
    if (!zipCodeValidateRegex.test(data.zipCode)) {
      error.zipCode = true
      newData.zipCode = ""

    }
    //check error for athlete age
    if (data.athleteAge === "") {

      error.athleteAge = true
      newData.athleteAge = ""

    }

    const numberValue = Number(data.athleteAge);
    if (numberValue >= 5 && numberValue <= 18) {


    } else {

      error.athleteAge = true
      newData.athleteAge = ""
    }
    setDataError({
      ...dataError,
      ...error
    })
    setData({ ...data, ...newData })


    // error do not call api

    for (const key in error) {
      if (error[key] === true) {
        setDisabled(false)
        return
      }
    }
    let data2 = {...data, test:"c"}
    // no erro call api
    const input = JSON.stringify(data2)
    // setDisabled(false)
    // return
    try {
      const response = await fetch(config.backendAPi + "/register/sportscalculatorpage2c", {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: input
      });






      const data = await response.json();

      if (data?.status === 0 && data?.statusText === "successful") {
        typeof window !== 'undefined' && localStorage.setItem('sport', input);
        typeof window !== 'undefined' && localStorage.setItem('sportResult', JSON.stringify(data));
        navigate('/resultsc');
      } else {
        setResponseError(data?.statusText ?? "Something went wrong!")
      }
      setDisabled(false)
    } catch (error) {

      setResponseError("Something went wrong!")
      setDisabled(false)
    }











  };





  return (

    <div className="has-background-white has-text-primary indexBody liftrocketCalcaulator">
      <HelmetIndex />
      <div className="calculatorBody">
        <CalLogo />
        <div className="calculatorData">
          {/* <div className="calculatorText">Ready to keep <span className="orange">
          your kid playing? 
          </span></div> */}
          <div className="calculatorHeadTitle">
            <img alt="Ready to keep your kid playing?" src={headTitle} />
          </div>
          <div className="calculatorText2">Use the <b className="calculatorText2Bold">LiftRocket Youth Sports Spend Calculator</b> to find out how much you’ll spend on your kid and we’ll send you $25 towards your sports budget! </div>

          {/* <div className="calculatorText3 orange"><img alt="check" src={checkBlue} />Free, easy, and instant results</div>
          <div className="calculatorText3"><img alt="check" src={checkOrange} />Personalized cost breakdown</div>
          <div className="calculatorText3 orange"><img alt="check" src={checkBlue} />Automatic scholarship grant entry</div>
          <div className="calculatorText3"><img alt="check" src={checkOrange} />Plan for your child’s entire sports journey</div> */}
          <div className="calculatorHeadText">
            <div> <img alt="Fill out the form below. Learn how much you’ll spend. Send us your registration receipt. Earn $25 for your sports budget" src={headTextC} /> </div>
            
          </div>


          <form className="calculatorForm" ref={myRef}>
            {/* <div className="calculatorFormTitle">The Youth Sports Spend Calculator</div> */}
            <Select options={options} placeholder={<div className="sportSelect">Your Athlete’s Sport</div>} onChange={(e) => { setData({ ...data, sportName: e.value }) }} styles={{}} components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null }} />

            <input placeholder={dataError.athleteAge ? "Enter age between 5-18" : "Your Athlete’s Age (5-18)"} value={data.athleteAge} onChange={(e) => {
              if (/^\d*$/.test(e.target.value)) {
                setData({ ...data, athleteAge: e.target.value })
              }


            }} className={dataError.athleteAge ? "calculatorFormInput calculatorFormInput-error error" : "calculatorFormInput"} type="text" />
            <input placeholder={dataError.zipCode ? "Enter valid US zip code" : "Zip code"} className={dataError.zipCode ? "calculatorFormInput calculatorFormInput-error error" : "calculatorFormInput"} value={data.zipCode} onChange={(e) => { setData({ ...data, zipCode: e.target.value }) }} type="text" />
            {/* <input placeholder={dataError.phoneNumber ? "Enter valid mobile number" : "Mobile number"} className={dataError.phoneNumber ? "calculatorFormInput calculatorFormInput-error error" : "calculatorFormInput"} value={data.phoneNumber} onChange={(e) => {
              if (/^\d*$/.test(e.target.value)) {
                setData({ ...data, phoneNumber: e.target.value })
              }

            }} type="tel" />
            <div className="calculatorFormBoxText">We will text you a code for your customized spend forecast. </div> */}
            <div className="calculatorFormButtonContainer">
              <button className="calculatorFormButton" disabled={disabled} onClick={(e) => { onSubmit(e) }}>
                Get me ready!
              </button>



            </div>
            {responseError === "" ? <div></div> : <div className="errorMessage">{responseError}</div>}
            <div className="calculatorFormText">We take your privacy seriously. Your information will never
              be shared or used without your permission. <a className="CalPrivatePolicy" rel="noopener noreferrer" target="_blank" href={"/privacypolicy"}>Privacy Policy</a>. </div>

          </form>

          <div className="middleBox">
            <div className="middleTitle">LEARN AND EARN</div>
            <div className="middleTitle2">Earn $25 towards your sports budget</div>
            <div className="middleText">The LiftRocket Youth Sports Scholarship program awards grants to deserving athletes from age 5 to 18 to cover the costs of participating in athletic activity. Grants are determined through evaluation of sports expense, family need and athletic achievement. Grant recipients are required to provide all information requested by the LiftRocket Youth Sports Expense Calculator and the complete the LiftRocket grant verification process after initial selection.</div>
          </div>

          <div className="middleBox">
            <div className="middleTitle">WHO ARE WE</div>
            <div className="middleTitle2">We keep kids playing</div>
            <div className="middleText">We understand that youth sports is an increasing cost for most families. This is a thank you for participating and helping the LiftRocket community. After you send your registration fee receipt, we will send you $25.  Fill out our form and click the button for your spend projection and instructions on how to send your receipt.</div>
          </div>

          <div className="middleBox">
            <div className="middleTitle">OUR VALUES</div>
            <div className="middleTitle2">Every kid deserves
              a chance to play. </div>
            <div className="middleText">Expense is the biggest barrier to participation in youth sports. Costs for equipment, travel, registration, and training add up fast and leave some families out.  We believe the youth sports community deserves a financial support system dedicated to leveling the playing field.</div>
          </div>
          <div className="orangeMiddleBoxImg">
            <div className="orangeMiddleBox middleBox">
              <div className="orangeMiddleBoxTitle">Stay ahead of the game </div>
              <div className="orangeMiddleBoxBody">Curious about the real cost of your kid's sports journey? Use our financial spend calculator and earn a chance to win a full year scholarship for your athlete.</div>
              <div>
                <button className="orangeMiddleBoxButton" onClick={() => { myRef.current.scrollIntoView() }}>
                  Get me ready!
                </button>
              </div>
            </div>

          </div>


        </div>



      </div>
      <CalFooter />


    </div>
  );
};
